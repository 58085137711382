import React, { createContext, useCallback, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import flatten from 'flat';

import vi from '@/locales/vi';
import en from '@/locales/en';

export const LOCALES = {
  vi: 'vi',
  en: 'en',
};

export const LanguageContext = createContext({});

const LanguageProvider = ({ children }) => {
  const LOCALE_MESSAGES = useMemo(
    () => ({
      vi: vi,
      en: en,
    }),
    []
  );

  const [locale, setLocale] = useState(LOCALES.vi);
  const [messages, setMessages] = useState(LOCALE_MESSAGES.vi);

  const changeLanguage = useCallback(
    (lang) => {
      setLocale(lang);
      setMessages(LOCALE_MESSAGES[lang]);
    },
    [LOCALE_MESSAGES]
  );

  const contextValue = useMemo(
    () => ({
      changeLanguage,
    }),
    [changeLanguage]
  );

  const flattenMessages = useMemo(() => flatten(messages), [messages]);

  return (
    <LanguageContext.Provider value={contextValue}>
      <IntlProvider locale={locale} key={locale} messages={flattenMessages}>
        {React.Children.only(children)}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LanguageProvider;
