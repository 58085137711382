export default {
  hello: 'Hello',
  message: {
    yes: 'Yes',
    no: 'No',
    mustBeMoreThanOne: 'Must be more than one letter.',
    mustBeGreaterThanZero: 'Must be greater than 0',
    mustBeNotMoreThan: 'Must be not more than {max} characters',
    mustBeGreaterThan: 'Must be greater than {min}',
    mustBeNotGreaterThan: 'Must be not greater than {max}',
    required: 'Required.',
    'max-5mb': 'Only upload document below 5mb',
    submitted: 'Submitted',
    complete: 'Complete',
    incomplete: 'Incomplete',
    mustBeValidEmail: 'Must be a valid email',
    doubleEmail: 'Email is duplicated',
  },
  'placeholder': {
    'company-name': 'Company ABC',
    'address': 'Address'
  },
  'back-to-main': 'Back to Main Application Menu',
  save: 'Save',
  delete: 'Delete',
  add: 'Add',
  'add-document': 'Add Document',
  cancel: 'Cancel',
  next: 'Next',
  previous: 'Previous',
  submit: 'Submit',
  'goto-main': 'Main Application',
  'income-verification': 'Income Verification',
  close: 'Close',
  required: 'Required',
  yes: 'Yes',
  no: 'No',
  'delete-confirmation': 'Do you want to delete?',
  modules: {
    auth: {
      features: {
        login: {
          title: 'Log into Homebase',
          description: 'Enter your email and we\'ll email you a login link',
          'send-login-email': 'Send Email',
          'do-not-have-account': 'Don\'t have an account?',
          'sign-up': 'Sign up',
          'email-length': 'Email is not more than 255 characters.',
          'email-valid': 'Must be a valid email.',
        },
        welcome: {
          title: 'Login sent!',
          description: 'Please check your email inbox for a login link.',
          back: 'Back to homepage',
        },
        signup: {
          title: 'Sign up',
          description: 'Create an account to start your application',
          already: 'Already have an account?',
          login: 'Log in',
          name: 'Name',
          'email-length': 'Email is not more than 255 characters.',
          'email-valid': 'Must be a valid email.',
        },
      },
    },
    process: {
      features: {
        welcome: {
          title: 'Welcome to Homebase!',
          description: 'Welcome to Homebase and we\'re excited to work with you. Please fill out the following questions and a member of our staff will be in touch to learn more  and customize a financing plan just for you.'
        },
        'legal-name': {
          title: 'Name',
          email: 'Email',
          description: 'Please enter your actual full legal name.',
          'email-title': 'Please enter your email.',
        },
        'mobile-contact': {
          title: 'Let\'s get started',
          description: 'First, we need your mobile contact information to send you updates about the status of your application.',
          phone: 'Phone Number',
          whatsapp: 'WhatsApp Number',
          zalo: 'Zalo Number',
          'invalid-phone': 'Invalid phone number.',
        },
        'planning-to-move': {
          title: 'When are you planning to move?',
          asap: 'ASAP',
          onetwo: '1-2 Months from now',
          twothree: '2-3 Months from now',
          threefour: '3-4 Months from now',
          notsure: 'Not sure',
        },
        'looking-to-live': {
          title: 'Where are you looking to live?',
        },
        'property-identify': {
          title: 'Do you already have a property identified?',
          'title-no-problem': 'No problem! Please come back when you have found a property.',
          'title-confirm': 'You indicated that you have not identified a property to purchase yet. Have you found a property yet?'
        },
        'financial-situation': {
          title: 'Tell us a bit about your financial situation',
          description: 'We’ll use this information to calculate your potential budget. Include all types of income for a more accurate result.',
          'gross-monthly': 'Your gross monthly income (VND)',
          'liquid-assets': 'Your approximate current savings and total liquid assets (bank accounts, cash, stocks, bonds, etc.)',
        },
        'how-to-hear': {
          title: 'How did you hear about Homebase?',
          email: 'E-mail',
          'facebook-ads': 'Facebok ads',
          'news-media': 'News media',
          'real-estate-agent': 'Real estate agent',
          'word-of-mouth': 'Word-of-mouth',
          'other': 'Other',
        },
        'contact-time': {
          title: 'Contact Time',
          description: 'What time is the most convenient for us to contact you?'
        },
        'thank-for-apply': {
          title: 'Thanks for applying!',
          description: 'Thank you for submitting your information! A Homebase representative will reach out soon. In the meantime, you can continue to the Main Application or schedule a time directly to speak with us.',
          schedule: 'Schedule a Time'
        },
        'co-buyer-confirmation': {
          'title': 'Joint Client(s)',
          'main-question': 'Are you applying with another person?',
          hint: 'Having a Joint Client(s) could increase your budget. A Joint Client(s) is any person that is willing to share your monthly payment liability.',
          'cobuyer-name': 'Joint Client\'s name',
          'cobuyer-email': 'Joint Client\'s email',
          'cobuyer-invite': 'Invite successfully sent at {time} on {date}',
          'cobuyer-information': 'Joint Client {index} information',
          'add-more-cobuyer': 'Add more',
        },
        'add-main-income': {
          'title-init': 'Primary income',
          'title-add-more': 'Additional income',
          description:
            'You’ll be able to add additional sources of income later. For now, choose your primary income type.',
          'description-add-more': 'Choose your secondary/any additional income that you have.',
          'salary-hourly-income': 'Salary/Hourly Income',
          'independent-contractor': 'Independent Contractor',
          'self-employed-sole-proprietor': 'Self-employed/Sole-Proprietor',
          'business-owner': 'Business Owner',
          'retired-no-income': 'Retired/No Income',
          'no-additional-income': 'No additional income',
        },
        'property': {
          'identity': {
            'property-type': {
              'title': 'What is the property type?',
              'apartment': 'Apartment',
              'villa': 'Villa/House in Project',
              'land-property': 'Landed Property (Detached House)',
              'land': 'Land',
              'officetel': 'Officetel/Condotel'
            },
            'title': 'Property Identified',
            'description': 'Provide some information regarding your identified property.',
            'property-name': 'What is the name of the property project?',
            'property-specify': 'What is the specific tower and unit number of the property?',
            'property-seller': 'Who is the property seller?',
            'bedroom': 'Number of Bedrooms?',
            'bathroom': 'Number of Bathrooms?',
            'real-estate-developer': 'Real estate developer',
            'another-seller': 'Another seller',
            'address': 'What is the address of the property?'
          },
          'detail': {
            title: 'Property Financial Details',
            'property-value': 'How much is the property value? (VND) ',
            'borrow-amount': 'How much financing do you need from Homebase? (VND) ',
            'borrow-period': 'What is the term of financing (in months) you need from Homebase?',
            'dont-know': 'Don’t know',
          },
          'document': {
            'title': 'Property Paperwork/Further Details',
            'paperwork-title': 'What title paperwork does the property have?',
            'paperwork': {
              'deposit': 'Deposit Contract',
              'spa': 'SPA',
              'pink-book': 'Pink Book',
              'long-term': 'Long-term Lease',
              'dontKnow': 'Don’t know'
            },
            yes: 'Yes',
            no: 'No',
            'quota-title': 'If the title under foreigner or local quota?',
            'quotas': {
              'foreigner': 'Foreigner',
              'local': 'Local',
              'dontKnow': 'Don’t know'
            },
            'has-document-title': 'Do you have any title paperwork associated with the property?',
            'has-document-description': 'Verify your property details by uploading the required documents. You’ll be prompted to upload a document or take a photo.',
            'upload-title': 'Property Title Documents',
            'upload-description': 'Documents including property deposit agreement, sales and purchase agreement, pink book, long-term-lease agreement, etc'
          },
          'gallery': {
            'has-gallery-title': 'Do you have any photos/video of the property?',
            'has-gallery-description': 'Verify your property details by uploading the required documents. You’ll be prompted to upload a document or take a photo.',
            'upload-title': 'Property Photos/Video',
            'upload-description': 'Any photos or videos inside, outside, or around the property',
            yes: 'Yes',
            no: 'No',
          }
        },
        'confirm-identity': {
          title: 'Confirm your identity documents',
          description: 'We will use this information to verify your identity.',
          passport: 'Passport (for Foreign nationals)',
          cmnd: 'CMND (for Vietnamese nationals)',
        },
        'salary-income': {
          name: 'Salary/Hourly Income {index}',
          'income-info': {
            title: 'Employer’s Details',
            description:
              'We need some details about your employer to verify your income.',
            'employer-name': 'Employer’s name',
            'start-date': 'Start Date',
            'employer-address': 'Employer address',
          },
          'income-salary': {
            title: 'Payment Information for {name}',
            'how-often': 'How often do you get paid for this job?',
            'gross-salary': 'Your average monthly gross/pre-tax pay (VND)',
            'daily': 'Daily',
            'weekly': 'Weekly',
            'every-2-weeks': 'Every 2 weeks',
            'every-month': 'Every month',
            'other': 'Other',
          },
          'income-payment': {
            title: 'Verify your {name} income',
            description:
              'Verify your income by uploading the required documents where the date of issuance and numerical values are clearly visible. You’ll be prompted to upload a document or take a photo.',
            'payment-doc': 'Last 6 months of personal bank statements',
            'payment-description': 'Statements that show the gross income for every month from your company.',
            'confirmation-doc': 'Employment Confirmation',
            'confirmation-description': 'Confirmation of employment issued by your employer that states you are currently employed by the company, your official start date and your monthly salary',
          },
          'income-confirm': {
            title:
              'Do you receive paychecks from more than one salaried or hourly job?',
            description:
              'The more income sources you provide, the better your approved budget will be. Select “No” if you don\'t have any.',
          },
        },
        'self-employed-income': {
          name: 'Self-employed/Sole-Proprietor {index}',
          'income-info': {
            title: 'Self-employed/Sole-Proprietor Details',
            description:
                'We’d love to learn more about your business and self-employment income.',
            'company-name': 'Company name',
            'start-date': 'Start Date',
            'company-address': 'Company address',
          },
          yes: 'Yes',
          no: 'No',
          'income-salary': {
            title: 'Self-employed/Sole-Proprietor Payment Information ',
            'gross-salary': 'On average, how much gross/pre-tax revenue do you make each month (VND)?',
            'daily': 'Daily',
            'weekly': 'Weekly',
            'every-2-weeks': 'Every 2 weeks',
            'every-month': 'Every month',
            'other': 'Other',
          },
          'income-payment': {
            title: 'Verify your Self-employed/Sole-Proprietor income',
            description:
                'Verify your income by uploading the required documents where the date of issuance and numerical values are clearly visible. You’ll be prompted to upload a document or take a photo.',
            'payment-doc': 'Last 6 months of personal bank statements',
            'payment-description': 'Statements that show your monthly gross income for every month.',
          },
          'income-confirm': {
            title:
                'Do you receive paychecks from more than one self-employment/business?',
            description:
                'The more income sources you provide, the better your approved budget will be. Select “No” if you don\'t have any.',
          },
        },
        'business-owner-income': {
          name: 'Business Owner {index}',
          'income-info': {
            title: 'Business Owner Details',
            description:
                'We’d love to learn more about your business details.',
            'company-name': 'Company name',
            'start-date': 'Start Date',
            'company-address': 'Company address',
          },
          'income-salary': {
            title: 'Business Owner Payment Information',
            'how-often': 'How often do you receive income from your business?',
            'gross-salary': 'On average, how much gross/pre-tax pay revenue does your business make each month (VND)?',
            'daily': 'Daily',
            'weekly': 'Weekly',
            'every-2-weeks': 'Every 2 weeks',
            'every-month': 'Every month',
            'other': 'Other'
          },
          'income-payment': {
            title: 'Verify your Business Owner income',
            description:
                'Verify your income by uploading the required documents where the date of issuance and numerical values are clearly visible. You’ll be prompted to upload a document or take a photo.',
            'payment-doc': 'Last 6 months of personal bank statements',
            'payment-description': 'Personal bank statements that show the gross income from your company.',
            'company-payment-doc': 'Last 6 months of company bank statements',
            'company-payment-description': 'Bank statements of your company that show inflow/outflow of capital.',
            'certificate-doc': 'Company Enterprise Registration Certificate (ERC)',
            'certificate-description': 'Legal registration certificate of your company issued by the government.',
            'charter-doc': 'Company Charter',
            'charter-description': 'Charter of your company as approved by the government.',
            'devidend-doc': 'Company Dividend Announcements',
            'devidend-description': 'Official acknowledgement of your company that funds disbursed to you from the company are classified as income, not loans or other debt arrangements.',
            'financial-doc': 'Company financial statements up to last financial quarter',
            'financial-description': 'Latest company financial statements.',
          },
          'income-confirm': {
            title:
                'Do you receive paychecks from more than one business?',
            description:
                'The more income sources you provide, the better your approved budget will be. Select “No” if you don\'t have any.',
          },
        },
        'independent-contract': {
          name: 'Independent Contractor {index}',
          'income-info': {
            title: 'Independent Contractor Details',
            description:
                'We’d love to learn more about your Independent Contractor income.',
            'company-name': 'Company name',
            'start-date': 'Start Date',
            'company-address': 'Company address',
          },
          'income-salary': {
            title: 'Independent Contractor Payment Information',
            'how-often': 'How often do you get paid for this job?',
            'gross-salary': 'Your average monthly gross/pre-tax pay (VND)',
            'daily': 'Daily',
            'weekly': 'Weekly',
            'every-2-weeks': 'Every 2 weeks',
            'every-month': 'Every month',
            'other': 'Other',
          },
          'income-payment': {
            title: 'Verify your Independent Contractor income',
            description:
                'Verify your income by uploading the required documents where the date of issuance and numerical values are clearly visible. You’ll be prompted to upload a document or take a photo.',
            'confirmation-doc': 'Employment/Engagement Confirmation',
            'confirmation-description': 'Any engagement contracts/confirmation that shows you are currently engaged, your engagement end date, and your engagement income.',
            'payment-doc': 'Last 6 months of personal bank statements',
            'payment-description': 'Statements that show your monthly gross income for every month',
          },
          'income-confirm': {
            title:
                'Do you receive paychecks from more than one Independent Contractor business?',
            description:
                'The more income sources you provide, the better your approved budget will be. Select “No” if you don\'t have any.',
          },
        },
        'retired-no-income': {
          name: 'Retired/No Income',
          'income-info': {
            title: 'Last Employment Details',
            description:
                'We’d love to learn more about your last employment details.',
            'company-name': 'Company name',
            'end-date': 'Ending Date',
            'employment-position': 'Employment Position',
            'terminate-reason': 'Reason for Termination',
            'total-liquid': 'Your approximate current total liquid assets (VND)'
          },
          'income-payment': {
            title: 'Verify your finance  details',
            description:
                'Verify your income by uploading the required documents where the date of issuance and numerical values are clearly visible. You’ll be prompted to upload a document or take a photo.',
            'personal-bank': 'Personal bank statements',
            'personal-bank-description': 'Statements that show your total current savings.',
            'liquid-investment-doc': 'Liquid investments or savings',
            'liquid-investment-description': 'Statements that show your total current liquid savings or investments including bonds, stocks, securities, deposits, etc.',
          }
        },
        'proof-of-funds': {
          confirm: {
            title: 'Confirm current total liquid assets',
            'confirm-question': 'You indicated your current approximate total liquid assets (cash, stocks, bonds, etc.) is {total}. Is this correct?',
            'ask-question': 'What is your approximate current total liquid assets (cash, stocks, bonds, etc.)',
            yes: 'Yes',
            no: 'No',
          },
          upload: {
            'title': 'Client current savings and liquid assets verification',
            'description': 'Verify your current savings and liquid assets by uploading the required documents. You’ll be prompted to upload a document or take a photo.',
            'personal-bank': 'Personal bank statements',
            'personal-bank-description': 'Statements that show your total current savings.',
            'liquid-investment': 'Liquid investments or savings',
            'liquid-investment-description': 'Statements that show your total current liquid savings or investments including bonds, stocks, securities, deposits, etc.',
            'confirm-question': 'Do you have additional bank/liquid assets statements that you want to upload?'
          },
          'debt-obligation': {
            title: 'Existing debt obligations {index}',
            'title-more': 'Do you have another existing debt obligation?',
            'confirm-question': 'Please indicate all existing debt obligations. Select “No” if you don\'t have any.',
            'exist-question': 'Do you have any existing debt obligations?',
            'description-info': 'Tell us about your existing debt obligations',
            'lender-name': 'Lender {index} Name',
            'total-amount': 'Total Amount (VND)',
            'purpose': 'Purpose',
            'purpose-placeholder': 'Bank loan to buy a house',
            'monthly-payment': 'Your average monthly payment (VND)',
            'verify-title': 'Existing Debt Obligations Verification',
            'verify-description': 'Verify your existing debt obligations by uploading the required documents. You’ll be prompted to upload a document or take a photo.',
            'doc-title': 'Debt obligations documents',
            'doc-description': 'Lending documents that show the total amount, terms and conditions, and payment schedules of your existing debt obligations',
            yes: 'Yes',
            no: 'No',
          }
        },
        'final-declaration': {
          title: 'You\'re almost there! Please read and accept the following terms and conditions below.',
          'message': 'To the best of my knowledge, the following are true:',
          'rule1': 'The documents and information of the client and/or joint client(s) provided in this application are true, accurate, and up to date',
          'rule2': 'The savings, income and other financial assets of the client and/or joint client(s) come from legal and well-documented sources and do not violate any AML/KYC policies in any jurisdiction',
          'rule3': 'The client and/or joint client(s) has/have never, due to their faults, been evicted or had a rental contract forcibly terminated',
          'rule4': 'The client and/or joint client(s) has/have never defaulted on a prior debt obligation',
          'rule5': 'The client and/or joint client(s) understand and agree to inform Homebase immediately if there are any changes to any of the information submitted in this application during the application process and afterwards, including but not limited to information pertaining to earnings, employment etc.',
          'rule6': 'The client and/or joint client(s) understand and agree that misrepresentation in any of the above areas may result in early termination of the contract between them and Homebase in addition to significant financial, legal, or other penalties/ remedies.',
          'agreement': 'I have read, understand, and agree with the terms and conditions above.',
          'submit-info': 'Submitted successfully at {time} on {date}'
        },
        'summary': {
          welcome: 'welcome to homebase!',
          'status-card': {
            title: 'Application needs additional information',
            description: 'Complete the tasks below to submit your application.',
            'title-review': 'We\'re reviewing your application',
            'description-review': 'We will get back to you with decision soon'
          },
          'joint-client': {
            title: 'Joint Client(s)',
            description: 'Are you planning to buy with someone else? Adding them can increase your chance of approval and increase your verified budget.',
            'button-add': 'Add Joint Client(s)',
            'invite-sent': 'Invite Sent'
          },
          'income-task': {
            title: 'Income sources',
            description: 'Add in your sources of income. This will help us understand your complete financial picture, even if you may have no or unstable income.',
            'button-add': 'Add income source'
          },
          'proof-of-funds': {
            title: 'Proof of Funds',
            description: 'Tell us about your current savings and assets',
            'liquid-assets-confirmed': 'Current Liquid Assets Confirmed',
            'liquid-assets-savings-verified': 'Current Liquid Assets and Savings Verified',
            'existing-debt-obligations': 'Existing Debt Obligations'
          },
          'final-declaration': {
            title: 'Final Declaration and Submission',
            description: 'Verify the terms and conditions and submit the application!',
            status: 'Submitted on',
            'sub-title': 'Final Declaration Submitted',
            'validate-message': 'It looks like there are still parts of the application that are not complete. Please finish these parts to submit your application.'
          },
          'property': {
            title: 'Property Information',
            description: 'Tell us about the property that you’re interested in. Don’t worry if you don’t have one now, you can come back to complete this section later.',
            'identified': 'Property Identified',
            'detail': 'Property Financial Details',
            'document': 'Property Documents',
            'gallery': 'Property Photos/Videos'
          },
          'identity-documents': {
            title: 'Identity Documents',
            description: 'Add in your identity documents to verify your identity.',
            'submitted': 'Identity Documents Submitted'
          },
          'question': {
            title: 'Questions about your application?',
            'get-in-touch': 'Get in touch! Email',
            'customer-support': 'and a customer representative will contact you soon!',
          },
        }
      },
    },
  },
  pages: {
    process: {
      welcome: {
        title: 'Pre-qualification',
      }
    },
  },
};
