import { lazy } from 'react';

const routes = [
  {
    path: '/auth',
    component: lazy(() => import('@/layouts/AuthLayout')),
    routes: [
      {
        path: '/auth/login',
        component: lazy(() => import('@/pages/auth/login')),
      },
      {
        path: '/auth/signup',
        component: lazy(() => import('@/pages/auth/signup')),
      },
      {
        path: '/auth/welcome',
        component: lazy(() => import('@/pages/auth/welcome')),
      },
    ],
  },
  {
    path: '/public',
    component: lazy(() => import('@/layouts/PublicLayout')),
    routes: [
      {
        path: '/public/process',
        component: lazy(() => import('@/layouts/MainLayout')),
        routes: [
          {
            path: '/public/process/welcome',
            component: lazy(() => import('@/pages/process/welcome')),
          },
          {
            path: '/public/process/legal-name',
            component: lazy(() => import('@/pages/process/legal-name')),
          },
        ],
      },
    ],
  },
  {
    path: '/',
    component: lazy(() => import('@/layouts/SecuredLayout')),
    routes: [
      {
        path: '/',
        exact: '/',
        redirect: '/process',
      },
      {
        path: '/process',
        component: lazy(() => import('@/layouts/MainLayout')),
        routes: [
          {
            path: '/process',
            exact: '/process',
            redirect: '/process/detect-process',
          },
          {
            path: '/process/detect-process',
            component: lazy(() => import('@/pages/process/detect-process')),
          },
          {
            path: '/process/mobile-contact',
            component: lazy(() => import('@/pages/process/mobile-contact')),
          },
          {
            path: '/process/how-to-hear',
            component: lazy(() => import('@/pages/process/how-to-hear')),
          },
          {
            path: '/process/planning-to-move',
            component: lazy(() => import('@/pages/process/planning-to-move')),
          },
          {
            path: '/process/looking-to-live',
            component: lazy(() => import('@/pages/process/looking-to-live')),
          },
          {
            path: '/process/working-with-agent',
            component: lazy(() => import('@/pages/process/working-with-agent')),
          },
          {
            path: '/process/property-confirm',
            component: lazy(() => import('@/pages/process/property-confirm')),
          },
          {
            path: '/process/property-no-problem',
            component: lazy(() =>
              import('@/pages/process/property-no-problem')
            ),
          },
          {
            path: '/process/property-identify',
            routes: [
              {
                path: '/process/property-identify/',
                exact: '/process/property-identify/',
                redirect: '/process/property-identify/process',
              },
              {
                path: '/process/property-identify/process',
                component: lazy(() =>
                  import('@/pages/process/property-identify-process')
                ),
              },
              {
                path: '/process/property-identify/init',
                component: lazy(() => import('@/pages/process/property-init')),
              },
              {
                path: '/process/property-identify/add',
                component: lazy(() =>
                  import('@/pages/process/property-identify')
                ),
              },
              {
                path: '/process/property-identify/:id',
                routes: [
                  {
                    path: '/process/property-identify/:id/',
                    exact: '/process/property-identify/:id/',
                    redirect: '/process/property-identify/:id/identify',
                  },
                  {
                    path: '/process/property-identify/:id/identify',
                    component: lazy(() =>
                      import('@/pages/process/property-identify')
                    ),
                  },
                  {
                    path: '/process/property-identify/:id/detail',
                    component: lazy(() =>
                      import('@/pages/process/property-detail')
                    ),
                  },
                  {
                    path: '/process/property-identify/:id/document',
                    component: lazy(() =>
                      import('@/pages/process/property-document')
                    ),
                  },
                  {
                    path: '/process/property-identify/:id/gallery',
                    component: lazy(() =>
                      import('@/pages/process/property-gallery')
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: '/process/financial-situation',
            component: lazy(() =>
              import('@/pages/process/financial-situation')
            ),
          },
          {
            path: '/process/confirm-identity',
            component: lazy(() => import('@/pages/process/confirm-identity')),
          },
          {
            path: '/process/edit-identity',
            component: lazy(() => import('@/pages/process/edit-identity')),
          },
          {
            path: '/process/co-buyer-confirmation',
            component: lazy(() =>
              import('@/pages/process/co-buyer-confirmation')
            ),
          },
          {
            path: '/process/contact-time',
            component: lazy(() => import('@/pages/process/contact-time')),
          },
          {
            path: '/process/thank-for-apply',
            component: lazy(() => import('@/pages/process/thank-for-apply')),
          },
          {
            path: '/process/final-declaration-submit',
            component: lazy(() =>
              import('@/pages/process/final-declaration-submit')
            ),
          },
          {
            path: '/process/add-main-income',
            component: lazy(() => import('@/pages/process/add-main-income')),
          },
          {
            path: '/process/salary-income',
            routes: [
              {
                path: '/process/salary-income/',
                exact: '/process/salary-income/',
                redirect: '/process/salary-income/add',
              },
              {
                path: '/process/salary-income/add',
                component: lazy(() => import('@/pages/process/salary-income')),
              },
              {
                path: '/process/salary-income/:id',
                component: lazy(() => import('@/pages/process/salary-income')),
              },
            ],
          },
          {
            path: '/process/self-employed-income',
            routes: [
              {
                path: '/process/self-employed-income/',
                exact: '/process/self-employed-income/',
                redirect: '/process/self-employed-income/add',
              },
              {
                path: '/process/self-employed-income/add',
                component: lazy(() =>
                  import('@/pages/process/self-employed-income')
                ),
              },
              {
                path: '/process/self-employed-income/:id',
                component: lazy(() =>
                  import('@/pages/process/self-employed-income')
                ),
              },
            ],
          },
          {
            path: '/process/business-owner-income',
            routes: [
              {
                path: '/process/business-owner-income/',
                exact: '/process/business-owner-income/',
                redirect: '/process/business-owner-income/add',
              },
              {
                path: '/process/business-owner-income/add',
                component: lazy(() =>
                  import('@/pages/process/business-owner-income')
                ),
              },
              {
                path: '/process/business-owner-income/:id',
                component: lazy(() =>
                  import('@/pages/process/business-owner-income')
                ),
              },
            ],
          },
          {
            path: '/process/independent-contract-income',
            routes: [
              {
                path: '/process/independent-contract-income/',
                exact: '/process/independent-contract-income/',
                redirect: '/process/independent-contract-income/add',
              },
              {
                path: '/process/independent-contract-income/add',
                component: lazy(() =>
                  import('@/pages/process/independent-contract-income')
                ),
              },
              {
                path: '/process/independent-contract-income/:id',
                component: lazy(() =>
                  import('@/pages/process/independent-contract-income')
                ),
              },
            ],
          },
          {
            path: '/process/retired-no-income',
            routes: [
              {
                path: '/process/retired-no-income/',
                exact: '/process/retired-no-income/',
                redirect: '/process/retired-no-income/add',
              },
              {
                path: '/process/retired-no-income/add',
                component: lazy(() =>
                  import('@/pages/process/retired-no-income')
                ),
              },
              {
                path: '/process/retired-no-income/:id',
                component: lazy(() =>
                  import('@/pages/process/retired-no-income')
                ),
              },
            ],
          },
          {
            path: '/process/confirm-total-liquid-assets',
            component: lazy(() =>
              import('@/pages/process/confirm-total-liquid-assets')
            ),
          },
          {
            path: '/process/upload-proof-of-funds',
            component: lazy(() =>
              import('@/pages/process/upload-proof-of-funds')
            ),
          },
          {
            path: '/process/debt-obligations',
            routes: [
              {
                path: '/process/debt-obligations/',
                exact: '/process/debt-obligations/',
                redirect: '/process/debt-obligations/process',
              },
              {
                path: '/process/debt-obligations/process',
                component: lazy(() =>
                  import('@/pages/process/debt-obligation-process')
                ),
              },
              {
                path: '/process/debt-obligations/init',
                component: lazy(() =>
                  import('@/pages/process/debt-obligation-init')
                ),
              },
              {
                path: '/process/debt-obligations/add',
                component: lazy(() =>
                  import('@/pages/process/debt-obligation-info')
                ),
              },
              {
                path: '/process/debt-obligations/confirm',
                component: lazy(() =>
                  import('@/pages/process/debt-obligations-confirm')
                ),
              },
              {
                path: '/process/debt-obligations/:id',
                routes: [
                  {
                    path: '/process/debt-obligations/:id/',
                    exact: '/process/debt-obligations/:id/',
                    redirect: '/process/debt-obligations/:id/info',
                  },
                  {
                    path: '/process/debt-obligations/:id/info',
                    component: lazy(() =>
                      import('@/pages/process/debt-obligation-info')
                    ),
                  },
                  {
                    path: '/process/debt-obligations/:id/document',
                    component: lazy(() =>
                      import('@/pages/process/debt-obligation-document')
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/summary',
        routes: [
          {
            path: '/summary',
            component: lazy(() => import('@/pages/process/summary')),
          },
        ],
      },
    ],
  },
];

export default routes;
