export default {
  hello: 'Xin chào',
  message: {
    yes: 'Rồi',
    no: 'Chưa',
    mustBeMoreThanOne: 'Cần có nhiều hơn một chữ số.',
    mustBeGreaterThanZero: 'Phải lớn hơn 0',
    mustBeNotMoreThan: 'Số ký tự không được lớn hơn {max}',
    mustBeGreaterThan: 'Phải lớn hơn {min}',
    mustBeNotGreaterThan: 'Phải không được lớn hơn {max}',
    required: 'Bắt buộc.',
    'max-5mb': 'Chỉ hỗ trợ tài liệu có kích thước dưới 5mb',
    submitted: 'Submitted',
    complete: 'Hoàn thành',
    incomplete: 'Chưa hoàn thành',
    mustBeValidEmail: 'Cần phải là email hợp lệ',
    doubleEmail: 'Email bị trùng lặp',
  },
  'placeholder': {
    'company-name': 'Công ty ABC',
    'address': 'Địa chỉ'
  },
  'back-to-main': 'Trở lại Mục lục Đăng Ký',
  save: 'Lưu',
  delete: 'Xoá',
  add: 'Thêm',
  'add-document': 'Thêm tài liệu',
  cancel: 'Hủy',
  next: 'Tiếp theo',
  previous: 'Quay về',
  submit: 'Nộp',
  'goto-main': 'Đăng Ký Thông Tin Chính',
  'income-verification': 'Income Verification',
  close: 'Đóng',
  required: 'Yêu cầu',
  yes: 'Có',
  no: 'Không',
  'delete-confirmation': 'Bạn có muốn xoá?',
  modules: {
    auth: {
      features: {
        login: {
          title: 'Đăng nhập vào Homebase',
          description: 'Nhập email và chúng tôi sẽ gửi link đăng nhập đến email của bạn',
          'send-login-email': 'Gửi email',
          'do-not-have-account': 'Chưa có tài khoản?',
          'sign-up': 'Đăng ký',
          'email-length': 'Tối đa 255 ký tự.',
          'email-valid': 'Cần phải là email hợp lệ.',
        },
        welcome: {
          title: 'Đã gởi thông tin đăng nhập!',
          description: 'Vui lòng kiểm tra email của bạn để có thông tin đăng nhập.',
          back: 'Back to homepage',
        },
        signup: {
          title: 'Đăng ký',
          description: 'Tạo tài khoản để bắt đầu sử dụng ứng dụng',
          already: 'Đã có sẵn tài khoản?',
          login: 'Đăng nhập',
          name: 'Tên',
          'email-length': 'Tối đa 255 ký tự.',
          'email-valid': 'Cần phải là email hợp lệ.',
        },
      },
    },
    process: {
      features: {
        welcome: {
          title: 'Chào mừng bạn đến với Homebase!',
          description: 'Chào mừng bạn đến với Homebase! Chúng tôi rất hân hạnh được đồng hành cùng bạn. Vui lòng điền thông tin cho những câu hỏi dưới đây. Nhân viên của chúng tôi sẽ liên lạc với bạn để hỗ trợ tư vấn và thiết kế cho bạn kế hoạch tài chính phù hợp.'
        },
        'legal-name': {
          title: 'Tên',
          email: 'Email',
          description: 'Vui lòng nhập tên đầy đủ (theo CMND/Hộ chiếu) của bạn.',
          'email-title': 'Vui lòng nhập địa chỉ email của bạn.',
        },
        'mobile-contact': {
          title: 'Bắt đầu nào',
          description: 'Trước tiên, vui lòng cung cấp số điện thoại liên lạc để chúng tôi cập nhật về trạng thái đơn đăng ký của bạn.',
          phone: 'Số Điện Thoại',
          whatsapp: 'Số Whatsapp',
          zalo: 'Số Zalo',
          'invalid-phone': 'Số điện thoại không hợp lệ.',
        },
        'planning-to-move': {
          title: 'Bạn dự định khi nào sẽ chuyển vào nhà mới?',
          asap: 'Ngay khi có thể',
          onetwo: '1-2 tháng kể từ bây giờ',
          twothree: '2-3 tháng kể từ bây giờ',
          threefour: '3-4 tháng kể từ bây giờ',
          notsure: 'Không chắc chắn',
        },
        'looking-to-live': {
          title: 'Bạn dự định sẽ sống ở khu vực nào?',
        },
        'property-identify': {
          title: 'Bạn đã xác định được bất động sản mục tiêu chưa?',
          'title-no-problem': 'Không sao! Hãy quay lại khi bạn đã tìm thấy bất động sản mà mình muốn mua.',
          'title-confirm': 'Bạn đã cho biết rằng bạn hiện chưa tìm được bất động sản mục tiêu nào để mua. Bạn đã tìm được bất kỳ bất động sản nào chưa?'
        },
        'financial-situation': {
          title: 'Vui lòng cho chúng tôi biết thêm về tình hình tài chính của bạn',
          description: 'Chúng tôi sẽ dùng thông tin này để tính toán ngân sách tiềm năng của bạn. Hãy gộp tất cả nguồn thu nhập mà bạn có để có được kết quả chính xác hơn.',
          'gross-monthly': 'Tổng thu nhập hàng tháng (VND)',
          'liquid-assets': 'Số tiền tiết kiệm hiện tại và tổng tài sản lưu động gần đúng của bạn (tài khoản ngân hàng, tiền mặt, cổ phiếu, trái phiếu, v.v.)',
        },
        'how-to-hear': {
          title: 'Bạn biết đến Homebase từ đâu?',
          email: 'Email',
          'facebook-ads': 'Quảng Cáo Facebook',
          'news-media': 'Báo Chí Truyền Thông',
          'real-estate-agent': 'Môi giới bất động sản',
          'word-of-mouth': 'Tin truyền miệng ',
          'other': 'Khác',
        },
        'identity': {
          'title': 'Identity Documents'
        },
        'contact-time': {
          title: 'Thời gian liên lạc',
          description: 'Thời gian phù hợp nhất để chúng tôi liên lạc với bạn là?'
        },
        'thank-for-apply': {
          title: 'Cảm ơn bạn đã đăng ký!',
          description: 'Cảm ơn bạn đã cung cấp thông tin! Nhân viên tư vấn của chúng tôi sẽ liên lạc với bạn sớm nhất có thể. Bạn cũng có thể tiếp tục xem thêm thông tin ở phần Đăng Ký Thông Tin Chính hoặc đặt lịch tư vấn với chúng tôi.',
          schedule: 'Đặt lịch tư vấn'
        },
        'co-buyer-confirmation': {
          'title': 'Khách Hàng Đồng Ký Kết',
          'main-question': 'Bạn có đăng ký tư vấn cùng với người nào khác không?',
          hint: 'Có thêm (nhiều) Khách Hàng Đồng Ký Kết có thể làm tăng ngân sách của bạn. Khách Hàng Đồng Ký Kết là người sẽ cùng chia khả năng thanh toán hàng tháng với bạn.',
          'cobuyer-name': 'Tên khách hàng đồng ý ký kết (theo CMND/Hộ chiếu)',
          'cobuyer-email': 'Email Khách Hàng Đồng Ký Kết',
          'cobuyer-invite': 'Đã gửi lời mời thành công vào lúc {time} ngày {date}',
          'cobuyer-information': 'Khách Hàng Đồng Ký Kết {index}',
          'add-more-cobuyer': 'Thêm Khách Hàng Đồng Ký Kết',
        },
        'add-main-income': {
          'title-init': 'Thu nhập chính',
          'title-add-more': 'Thu nhập khác',
          description:
              'Bạn có thể thêm những nguồn thu nhập khác nhau. Vui lòng chọn nguồn thu nhập chính của bạn.',
          'description-add-more': 'Chọn nguồn thu nhập khác của bạn.',
          'salary-hourly-income': 'Lương/Thu nhập theo giờ',
          'independent-contractor': 'Lao động tự do',
          'self-employed-sole-proprietor': 'Tự kinh doanh/Chủ sở hữu độc quyền',
          'business-owner': 'Chủ doanh nghiệp',
          'retired-no-income': 'Đã nghỉ hưu/ Không có thu nhập',
          'no-additional-income': 'Không có thu nhập khác',
        },
        'property': {
          'identity': {
            'property-type': {
              'title': 'Loại hình bất động sản?',
              'apartment': 'Căn hộ chung cư',
              'villa': 'Villa/ Nhà thuộc dự án',
              'land-property': 'Bất động sản gắn liền đất (Nhà biệt lập)',
              'land': 'Đất',
              'officetel': 'Căn hộ văn phòng/ Căn hộ thuộc khách sạn (Officetel/Condotel)'
            },
            'title': 'Bất động sản mục tiêu',
            'description': 'Vui lòng cung cấp thông tin về bất động sản mục tiêu của bạn.',
            'property-name': 'Tên của dự án này là gì?',
            'property-specify': 'Tên tòa và mã căn hộ?',
            'property-seller': 'Ai là người bán bất động sản này?',
            'bedroom': 'Số phòng ngủ?',
            'bathroom': 'Số nhà vệ sinh?',
            'real-estate-developer': 'Chủ Đầu Tư',
            'another-seller': 'Người bán khác',
            'address': 'Địa chỉ của bất động sản này là?'
          },
          'detail': {
            title: 'Chi tiết liên quan đến tài chính về bất động sản',
            'property-value': 'Giá của bất động sản này là bao nhiêu? (VND) ',
            'borrow-amount': 'Bạn cần Homebase hỗ trợ tài chính bao nhiêu? (VND) ',
            'borrow-period': 'Thời hạn tài trợ (tính bằng tháng) bạn cần từ Homebase là trong bao lâu?',
            'dont-know': 'Không biết',
          },
          'document': {
            'title': 'Chi tiết về thủ tục giấy tờ của bất động sản',
            'paperwork-title': 'Loại giấy chứng nhận tài sản mà bất động sản này đang có là?',
            'paperwork': {
              'deposit': 'Hợp đồng cọc',
              'spa': 'Hợp đồng mua bán',
              'pink-book': 'Sổ hồng/ Sổ đỏ',
              'long-term': 'Hợp đồng thuê dài hạn',
              'dontKnow': 'Không biết'
            },
            yes: 'Có',
            no: 'Không',
            'quota-title': 'Giấy chứng nhận này thuộc hạn ngạch người nước ngoài hay người địa phương?',
            'quotas': {
              'foreigner': 'Người nước ngoài',
              'local': 'Người địa phương',
              'dontKnow': 'Không biết'
            },
            'has-document-title': 'Bạn có bất kỳ giấy tờ liên quan đến bất động sản này không?',
            'has-document-description': 'Xác minh chi tiết về bất động sản bằng cách tải lên những tài liệu giấy tờ được yêu cầu. Bạn sẽ được nhắc để tải lên tài liệu hoặc chụp hình.',
            'upload-title': 'Giấy tờ chứng nhận quyền sở hữu bất động sản',
            'upload-description': 'Những tài liệu gồm thỏa thuận đặt cọc, hợp đồng mua bán, sổ hồng/ sổ đỏ, hợp đồng thuê dài hạn, v.v.'
          },
          'gallery': {
            'has-gallery-title': 'Bạn có bất kỳ hình ảnh/ video nào về bất động sản này không?',
            'has-gallery-description': 'Xác minh chi tiết về bất động sản bằng cách tải lên những tài liệu giấy tờ được yêu cầu. Bạn sẽ được nhắc để tải lên tài liệu hoặc chụp hình.',
            'upload-title': 'Hình ảnh/ video về bất động sản',
            'upload-description': 'Bất kỳ hình ảnh hoặc video về bên trong, bên ngoài hoặc xong quanh bất động sản',
            yes: 'Có',
            no: 'Không',
          }
        },
        'confirm-identity': {
          title: 'Kiểm chứng giấy tờ danh tính cá nhân',
          description: 'Chúng tôi sẽ dùng thông tin này để xác nhận danh tính của bạn.',
          passport: 'Hộ Chiếu (Đối với người có quốc tịch nước ngoài)',
          cmnd: 'CMND (Đối với người có quốc tịch Việt Nam)',
        },
        'salary-income': {
          name: 'Lương/Thu Nhập Theo Giờ {index}',
          'income-info': {
            title: 'Thông tin về nhà tuyển dụng',
            description: 'Chúng tôi cần một số thông tin về nhà tuyển dụng của bạn để kiểm chứng lại thu nhập của bạn.',
            'employer-name': 'Tên Nhà Tuyển Dụng',
            'start-date': 'Ngày bắt đầu',
            'employer-address': 'Employer address',
          },
          'income-salary': {
            title: 'Thông tin về thu nhập {name}',
            'how-often': 'Bao lâu thì bạn sẽ được trả thù lao cho công việc này?',
            'gross-salary': 'Tổng trung bình thu nhập hàng tháng/ trước thuế (VND)',
            'daily': 'Mỗi ngày',
            'weekly': 'Hàng tuần',
            'every-2-weeks': 'Mỗi 2 tuần',
            'every-month': 'Mỗi tháng',
            'other': 'Khác',
          },
          'income-payment': {
            title: 'Kiểm chứng thu nhập từ {name}',
            description:
                'Vui lòng kiểm chứng lại thu nhập của bạn bằng cách đăng tải những giấy tờ được yêu cầu, chú ý sử dụng hình ảnh có thể nhìn thấy rõ ràng ngày phát hành và các giá trị bằng số. Bạn sẽ được nhắc tải lên tài liệu hoặc chụp ảnh.',
            'payment-doc': 'Sao kê ngân hàng của 6 tháng vừa qua',
            'payment-description': 'Báo cáo hiển thị tổng thu nhập hàng tháng từ công ty của bạn.',
            'confirmation-doc': 'Xác nhận tuyển dụng',
            'confirmation-description': 'Giấy xác nhận tuyển dụng từ nhà tuyển dụng của bạn mà ghi rõ bạn hiện đang làm việc tại công ty này, ngày bắt đầu làm việc chính thức và mức lương hàng tháng của bạn',
          },
          'income-confirm': {
            title:
                'Bạn có nhận được tiền lương từ nhiều hơn một công việc được trả lương hoặc làm theo giờ không?',
            description:
                'Khi bạn cung cấp càng nhiều nguồn thu nhập, ngân sách của bạn sẽ càng mạnh hơn. Chọn "Không" nếu bạn không có bất kỳ khoản nào khác.',
          },
        },
        'self-employed-income': {
          name: 'Tự kinh doanh/Chủ sở hữu độc quyền {index}',
          'income-info': {
            title: 'Thông tin của người Tự kinh doanh/Chủ sở hữu độc quyền',
            description:
                'Vui lòng cung cấp thông tin về doanh nghiệp của bạn và chi tiết thu nhập từ việc tự kinh doanh.',
            'company-name': 'Tên công ty',
            'start-date': 'Ngày bắt đầu',
            'company-address': 'Địa chỉ',
          },
          yes: 'Có',
          no: 'Không',
          'income-salary': {
            title: 'Thông tin về thu nhập của người Tự kinh doanh/Chủ sở hữu độc quyền',
            'gross-salary': 'Trung bình thì tổng thu nhập/trước thuế mà bạn nhận được là bao nhiêu mỗi tháng (VND)?',
            'daily': 'Mỗi ngày',
            'weekly': 'Hàng tuần',
            'every-2-weeks': 'Mỗi 2 tuần',
            'every-month': 'Mỗi tháng',
            'other': 'Khác',
          },
          'income-payment': {
            title: 'Kiểm chứng thu nhập của Tự Kinh Doanh/Chủ Sở Hữu Độc Quyền',
            description:
                'Vui lòng xác nhận lại thu nhập của bạn bằng cách đăng tải những giấy tờ được yêu cầu, chú ý sử dụng hình ảnh có thể nhìn thấy rõ ràng ngày phát hành và các giá trị bằng số. Bạn sẽ được nhắc tải lên tài liệu hoặc chụp ảnh.',
            'payment-doc': 'Sao kê ngân hàng của bạn trong 6 tháng vừa qua',
            'payment-description': 'Những báo cáo thể hiện tổng thu nhập của bạn mỗi tháng',
          },
          'income-confirm': {
            title:
                'Bạn có thù lao từ nhiều hơn một doanh nghiệp Tự Kinh Doanh/Chủ Sở Hữu Độc Quyền không?',
            description:
                'Khi bạn cung cấp càng nhiều nguồn thu nhập, ngân sách của bạn sẽ càng mạnh hơn. Chọn "Không" nếu bạn không có bất kỳ khoản nào khác.',
          },
        },
        'business-owner-income': {
          name: 'Chủ doanh nghiệp {index}',
          'income-info': {
            title: 'Thông tin Chủ Doanh Nghiệp',
            description:
                'Vui lòng cung cấp cho chúng tôi thông tin về doanh nghiệp của bạn.',
            'company-name': 'Tên Công Ty',
            'start-date': 'Ngày bắt đầu',
            'company-address': 'Địa chỉ',
          },
          'income-salary': {
            title: 'Thông Tin Thanh Toán Của Chủ Doanh Nghiệp',
            'how-often': 'Bao lâu thì bạn nhận được thu nhập từ doanh nghiệp của mình?',
            'gross-salary': 'Trung bình thì tổng thu nhập/trước thuế mà doanh nghiệp của bạn đạt được là bao nhiêu mỗi tháng (VND)?',
            'daily': 'Mỗi ngày',
            'weekly': 'Hàng tuần',
            'every-2-weeks': 'Mỗi 2 tuần',
            'every-month': 'Mỗi tháng',
            'other': 'Khác',
          },
          'income-payment': {
            title: 'Kiểm chứng thu nhập của Chủ Doanh Nghiệp',
            description:
                'Vui lòng xác nhận lại thu nhập của bạn bằng cách đăng tải những giấy tờ được yêu cầu, chú ý sử dụng hình ảnh có thể nhìn thấy rõ ràng ngày phát hành và các giá trị bằng số. Bạn sẽ được nhắc tải lên tài liệu hoặc chụp ảnh.',
            'payment-doc': 'Sao kê ngân hàng của bạn trong 6 tháng vừa qua',
            'payment-description': 'Báo cáo thể hiện tổng thu nhập của bạn từ công ty',
            'company-payment-doc': 'Sao kê ngân hàng của công ty trong 6 tháng vừa qua',
            'company-payment-description': 'Báo cáo thể hiện dòng vốn vào/ra của công ty bạn',
            'certificate-doc': 'Giấy chứng nhận đăng ký doanh nghiệp (ERC)',
            'certificate-description': 'Giấy phép đăng ký kinh doanh của công ty được cấp bởi chính quyền.',
            'charter-doc': 'Điều lệ công ty',
            'charter-description': 'Điều lệ của công ty bạn được thông qua bởi chính quyền.',
            'devidend-doc': 'Thông báo Cổ tức của Công ty',
            'devidend-description': 'Xác nhận chính thức của công ty bạn về các khoản tiền đã giải ngân cho bạn từ công ty được phân loại là thu nhập, không phải các khoản vay hoặc các thỏa thuận nợ khác.',
            'financial-doc': 'Báo cáo tài chính của công ty cho đến quý vừa rồi',
            'financial-description': 'Những báo cáo tài chính gần đây nhất của công ty.',
          },
          'income-confirm': {
            title:
                'Bạn có nhận lương từ nhiều doanh nghiệp không?',
            description:
                'Khi bạn cung cấp càng nhiều nguồn thu nhập, ngân sách của bạn sẽ càng mạnh hơn. Chọn "Không" nếu bạn không có bất kỳ khoản nào khác. ',
          },
        },
        'independent-contract': {
          name: 'Lao Động Tự Do {index}',
          'income-info': {
            title: 'Thông tin của người Lao Động Tự Do',
            description:
                'Vui lòng cung cấp thông tin về thu nhập Lao Động Tự do của bạn.',
            'company-name': 'Tên Công Ty',
            'start-date': 'Ngày Bắt Đầu',
            'company-address': 'Địa chỉ',
          },
          'income-salary': {
            title: 'Thông tin về thu nhập của Lao Động Tự Do',
            'how-often': 'Bao lâu thì bạn nhận được thu nhập từ doanh nghiệp của mình?',
            'gross-salary': 'Tổng trung bình thu nhập hàng tháng/ trước thuế (VND)',
            'daily': 'Mỗi ngày',
            'weekly': 'Hàng tuần',
            'every-2-weeks': 'Mỗi 2 tuần',
            'every-month': 'Mỗi tháng',
            'other': 'Khác',
          },
          'income-payment': {
            title: 'Kiểm chứng thu nhập của Lao Động Tự Do',
            description:
                'Vui lòng xác nhận lại thu nhập của bạn bằng cách đăng tải những giấy tờ được yêu cầu, chú ý sử dụng hình ảnh có thể nhìn thấy rõ ràng ngày phát hành và các giá trị bằng số. Bạn sẽ được nhắc tải lên tài liệu hoặc chụp ảnh.',
            'confirmation-doc': 'Xác nhận cam kết',
            'confirmation-description': 'Bất kỳ hợp đồng thỏa thuận/ giấy cam kết nào thể hiện rằng bạn hiện đang làm việc ở đây, ngày kết thúc hợp đồng, và thu nhập từ công việc này.',
            'payment-doc': 'Sao kê ngân hàng của bạn trong 6 tháng vừa qua',
            'payment-description': 'Những báo cáo thể hiện tổng thu nhập của bạn mỗi tháng',
          },
          'income-confirm': {
            title:
                'Bạn có thù lao từ nhiều hơn một doanh nghiệp Lao Động Tự Do không?',
            description:
                'Khi bạn cung cấp càng nhiều nguồn thu nhập, ngân sách của bạn sẽ càng mạnh hơn. Chọn "Không" nếu bạn không có bất kỳ khoản nào khác',
          },
        },
        'retired-no-income': {
          name: 'Đã nghỉ hưu/ Không có thu nhập',
          'income-info': {
            title: 'Last Employment Details',
            description:
                'We’d love to learn more about your last employment details.',
            'company-name': 'Company name',
            'end-date': 'Ending Date',
            'employment-position': 'Employment Position',
            'terminate-reason': 'Reason for Termination',
            'total-liquid': 'Your approximate current total liquid assets (VND)'
          },
          'income-payment': {
            title: 'Verify your finance  details',
            description:
                'Verify your income by uploading the required documents where the date of issuance and numerical values are clearly visible. You’ll be prompted to upload a document or take a photo.',
            'personal-bank': 'Personal bank statements',
            'personal-bank-description': 'Statements that show your total current savings.',
            'liquid-investment-doc': 'Liquid investments or savings',
            'liquid-investment-description': 'Statements that show your total current liquid savings or investments including bonds, stocks, securities, deposits, etc.',
          }
        },
        'proof-of-funds': {
          confirm: {
            title: 'Xác nhận tổng tài sản lưu động hiện tại',
            'confirm-question': 'Bạn đã cho biết rằng tổng số tiền tiết kiệm và tài sản lưu động gần đúng hiện tại của mình (tài khoản ngân hàng, tiền mặt, cổ phiếu, trái phiếu, v.v.) là {total}. Điều này có chính xác không?',
            'ask-question': 'Tổng số tiền tiết kiệm và tài sản lưu động gần đúng hiện tại của mình (tài khoản ngân hàng, tiền mặt, cổ phiếu, trái phiếu, v.v.) là bao nhiêu?',
            yes: 'Đúng',
            no: 'Không',
          },
          upload: {
            'title': 'Số tiền tiết kiệm hiện có của Khách Hàng và xác minh tài sản thanh khoản',
            'description': 'Vui lòng xác minh số tiền tiết kiệm và tài sản lưu động hiện có của bạn bằng cách tải lên những tài liệu được yêu cầu dưới đây. Bạn có thể tải lên bằng file tài liệu hoặc chụp hình.',
            'personal-bank': 'Sao kê ngân hàng',
            'personal-bank-description': 'Những báo cáo thể hiện tổng tiết kiệm hiện có của bạn.',
            'liquid-investment': 'Đầu tư ngắn hạn hoặc tiết kiệm',
            'liquid-investment-description': 'Những báo cáo thể hiện tổng tài sản lưu động hiện có của bạn bao gồm cổ phiếu, trái phiếu, chứng khoán, tiền gửi, v.v.',
            'confirm-question': 'Bạn có thêm cáo cáo thể hiện những khoản đầu tư ngắn hạn hay tiết kiệm khác?'
          },
          'debt-obligation': {
            title: 'Nợ {index} Phải Trả Hiện Có',
            'title-more': 'Bạn có khoản nợ khác phải trả không?',
            'confirm-question': 'Vui lòng cho chúng tôi biết về tất cả các khoản nợ phải trả của bạn. Chọn "Không" nếu bạn không có bất kỳ khoản nào khác.',
            'exist-question': 'Hiện nay, bạn có bất kỳ khoản nợ phải trả nào không?',
            'description-info': 'Vui lòng cho chúng tôi biết về khoản nợ phải trả hiện có của bạn',
            'lender-name': 'Tên Người Cho Vay {index}',
            'total-amount': 'Tổng thanh toán (VND)',
            'purpose': 'Mục đích',
            'purpose-placeholder': 'Hỗ trợ mua nhà',
            'monthly-payment': 'Trung bình tổng thanh toán hàng tháng (VND)',
            'verify-title': 'Xác minh về khoản nợ phải trả hiện có',
            'verify-description': 'Xác minh lại về khoản nợ phải trả hiện có của bạn bằng cách tải lên những tài liệu giấy tờ được yêu cầu. Bạn sẽ được nhắc để tải lên tài liệu hoặc chụp hình.',
            'doc-title': 'Tài liệu hay giấy tờ về khoản nợ phải trả',
            'doc-description': 'Các chứng từ cho vay thể hiện tổng số tiền, các điều khoản và điều kiện cũng như lịch trình thanh toán của các khoản nợ phải trả hiện có của bạn',
            yes: 'Có',
            no: 'Không',
          }
        },
        'final-declaration': {
          title: 'Bạn sắp hoàn thành rồi! Vui lòng đọc và chấp nhận các điều khoản và điều kiện dưới đây.',
          'message': 'Theo hiểu biết của tôi, những điều sau là đúng:',
          'rule1': 'Những giấy tờ, tài liệu và thông tin của khách hàng và/hoặc (những) khách hàng đồng ký kết đã cung cấp trong đơn đăng ký này đều đúng, chính xác, và mới nhất',
          'rule2': 'Các khoản tiết kiệm, thu nhập và các tài sản tài chính khác của khách hàng và / hoặc (các) khách hàng đồng ký kết đều đến từ các nguồn hợp pháp và được ghi chép đầy đủ và không vi phạm chính sách Xác Minh Khách Hàng/Chống Rửa Tiền (AML/KYC) dưới bất kỳ hình thức nào',
          'rule3': 'Khách hàng và / hoặc (các) khách hàng đồng ký kết chưa bao giờ, do lỗi của họ, bị đuổi ra khỏi hoặc bị buộc phải chấm dứt hợp đồng cho thuê',
          'rule4': 'Khách hàng và / hoặc (các) khách hàng đồng ký kết chưa bao giờ vỡ nợ trong bất kỳ khoản nợ phải trả nào trước đó',
          'rule5': 'Khách hàng và / hoặc (các) khách hàng đồng ký kết hiểu và đồng ý thông báo cho Homebase ngay nếu có bất kỳ thay đổi nào về thông tin đã cung cấp trong đơn đăng ký này trong suốt quá trình đăng ký và về sau, bao gồm nhưng không giới hạn ở thông tin liên quan đến thu nhập, việc làm, v.v.',
          'rule6': 'Khách hàng và / hoặc (các) khách hàng đồng ký hiểu và đồng ý rằng việc trình bày sai trong bất kỳ phần nào ở trên đều có thể dẫn đến việc chấm dứt sớm hợp đồng giữa họ và Homebase cùng với các hình phạt / biện pháp khắc phục hậu quả đáng kể về tài chính, pháp lý hoặc khác.',
          'agreement': 'Tôi đã đọc, hiểu, và đồng ý với các điều khoản và điều kiện trên.',
          'submit-info': 'Gửi thành công vào lúc {time} ngày {date}'
        },
        'summary': {
          welcome: 'chào mừng bạn đến Homebase!',
          'status-card': {
            title: 'Đơn đăng ký cần thêm thông tin',
            description: 'Hoàn thành những việc dưới đây để nộp đăng ký của bạn.',
            'title-review': 'Chúng tôi đang kiểm tra đơn đăng ký của bạn',
            'description-review': 'Chúng tôi sẽ liên lạc lại với bạn về kết quả sớm thôi'
          },
          'joint-client': {
            title: '(Các) Khách hàng đồng ký kết',
            description: 'Bạn có định mua với người khác không? Việc thêm danh sách này có thể tăng cơ hội được chấp thuận và tăng ngân sách đã xác minh của bạn.',
            'button-add': 'Thêm (Các) Khách hàng đồng ký kết',
            'invite-sent': 'Đã Mời'
          },
          'income-task': {
            title: 'Các nguồn thu nhập',
            description: 'Thêm nguồn thu nhập của bạn. Điều này giúp chúng tôi hiểu hơn tình hình tài chính của bạn, ngay cả khi bạn không có thu nhập hoặc thu nhập không ổn định.',
            'button-add': 'Thêm nguồn thu nhập'
          },
          'proof-of-funds': {
            title: 'Chứng minh nguồn vốn',
            description: 'Cho chúng tôi biết tổng số tiền tiết kiệm và tài sản lưu động hiện tại của bạn',
            'liquid-assets-confirmed': 'Tài sản lưu động hiện tại đã được xác nhận',
            'liquid-assets-savings-verified': 'Tài sản lưu động hiện có và tiết kiệm đã xác minh',
            'existing-debt-obligations': 'Khoản nợ phải trả hiện có'
          },
          'final-declaration': {
            title: 'Cam kết và Nộp đơn đăng ký',
            description: 'Xác nhận các điều khoản và điều kiện và gửi đơn đăng ký!',
            status: 'Gửi đơn lúc',
            'sub-title': 'Cam kết cuối cùng đã nộp',
            'validate-message': 'Dường như bạn còn một số thông tin chưa hoàn thành. Vui lòng kiểm tra lại và hoàn thành trước khi nộp đơn.'
          },
          'property': {
            title: 'Thông tin về bất động sản',
            description: 'Cho chúng tôi biết về bất động sản bạn quan tâm. Đừng lo lắng nếu bạn chưa có bây giờ, bạn có thể quay lại để hoàn thành thông tin này sau.',
            'identified': 'Bất động sản mục tiêu',
            'detail': 'Chi tiết tài chính tài sản',
            'document': 'Giấy tờ về bất động sản',
            'gallery': 'Hình ảnh/ video của bất động sản'
          },
          'identity-documents': {
            title: 'Giấy tờ về danh tính',
            description: 'Thêm giấy tờ về danh tính để xác thực danh tính của bạn.',
            'submitted': 'Giấy tờ về danh tính đã nộp'
          },
          'question': {
            title: 'Có câu hỏi về đơn đăng ký của bạn?',
            'get-in-touch': 'Hãy liên lạc với chúng tôi qua',
            'customer-support': 'và Trung Tâm Chăm Sóc Khách Hàng của chúng tôi sẽ hỗ trợ bạn.',
          },
        }
      },
    },
  },
  pages: {
    process: {
      welcome: {
        title: 'Thông Tin Đăng Ký',
      }
    },
  },
};
